import Work1 from "../../assets/work1.jpg";
import Work2 from "../../assets/work2.jpg";
import Work3 from "../../assets/work3.jpg";
import Work4 from "../../assets/work4.jpg";
import Work5 from "../../assets/work5.jpg";

export const projectsData = [
    {
      id: 1,
      image: Work1,
      title: "Airbnb",
      category: "web",
      href:"rent-example-clone-two.vercel.app"
    },
    {
      id: 2,
      image: Work2,
      title: "News",
      category: "app",
      href: 'https://news-my-desing.vercel.app/' ,
    },
    {
      id: 3,
      image: Work3,
      title: "Brand design",
      category: "web",
      href: 'https://news-my-desing.vercel.app/' ,
    },
    {
      id: 4,
      image: Work4,
      title: "App movil",
      category: "app",
      href: 'https://news-my-desing.vercel.app/',
    },
    {
      id: 5,
      image: Work5,
      title: "Web design",
      category: "web",
      href: 'https://news-my-desing.vercel.app/',
    },
  ];

  export const projectsNav = [
    {
        name: 'all',
    },
    {
        name: 'web',
    },
    {
        name: 'app',
    },
  ]