import React, { useState } from 'react'
import "./qualification.css"

const Qualification = () => {

  const[toggleState, setToggleState]= useState(1);

    const toggleTab=(index)=>{
      setToggleState(index);
    }
  return (
    <section className="qualification section">
      <h2 className="section__title">Qualification</h2>
        <span className="section__subtitle">My personel journey</span>

        <div className="qualification__container container">
          <div className="qualification__tabs">
            <div className={toggleState === 1 ? "qualification__button qualification__active button--flex" : "qualification__button button--flex"} onClick={() => toggleTab(1)}>
              <i className="uil uil-graduation-cap qualification__icon"></i>
              Education
            </div>

            <div className={toggleState === 2 ? "qualification__button qualification__active button--flex" : "qualification__button button--flex"} onClick={() => toggleTab(2)}>
              <i className="uil uil-briefcase-alt qualification__icon"></i>
              Experience
            </div>
          </div>

          <div className="qualification__sections">
            <div className={ toggleState === 1 ? "qualification__content qualification__content-active" : "qualification__content"}>
              <div className="qualification__data">
                <div>
                  <h3 className="qualification__title"> Bilge Adam </h3>
                  <span className="qualification__subtitle"> Bilge Adam Boost Program </span>
                  <div className="qualification__calender">
                    <i className="uil uil-calendar-alt"></i>
                    2021 - 2021
                  </div>
                </div>
                <div>
                  <span className="qualification__rounder"></span>
                  <span className="qualification__line"></span>
                </div>
              </div>

              <div className="qualification__data">
                <div></div>

                <div>
                  <span className="qualification__rounder"></span>
                  <span className="qualification__line"></span>
                </div>

                <div>
                  <h3 className="qualification__title">University</h3>
                  <span className="qualification__subtitle"> Mehmet Akif Ersoy University </span>
                  <div className="qualification__calender">
                    <i className="uil uil-calendar-alt"></i>
                    2015 - 2021
                  </div>
                </div>
                
              </div>

              <div className="qualification__data">
                <div>
                  <h3 className="qualification__title"> High school </h3>
                  <span className="qualification__subtitle"> Turkey</span>
                  <div className="qualification__calender">
                    <i className="uil uil-calendar-alt"></i>
                    2011 - 2015
                  </div>
                </div>
                <div>
                  <span className="qualification__rounder"></span>
                  <span className="qualification__line"></span>
                </div>
              </div>

              
            </div>

            <div className={ toggleState === 2 ? "qualification__content qualification__content-active" : "qualification__content"}>
              <div className="qualification__data">
                <div>
                  <h3 className="qualification__title">Frontend Developer</h3>
                  <span className="qualification__subtitle"> Omreon Information Technologies </span>
                  <div className="qualification__calender">
                    <i className="uil uil-calendar-alt"></i>
                    2022 - 2023
                  </div>
                </div>
                <div>
                  <span className="qualification__rounder"></span>
                  <span className="qualification__line"></span>
                </div>
              </div>

              <div className="qualification__data">
                <div></div>

                <div>
                  <span className="qualification__rounder"></span>
                  <span className="qualification__line"></span>
                </div>

                <div>
                  <h3 className="qualification__title">Test Engineer</h3>
                  <span className="qualification__subtitle"> Fibabanka </span>
                  <div className="qualification__calender">
                    <i className="uil uil-calendar-alt"></i>
                    2022 - 2022
                  </div>
                </div>
                
              </div>

              <div className="qualification__data">
                <div>
                  <h3 className="qualification__title">Junior Frontend Developer</h3>
                  <span className="qualification__subtitle"> Fowcrm </span>
                  <div className="qualification__calender">
                    <i className="uil uil-calendar-alt"></i>
                    2021 - 2021
                  </div>
                </div>
                <div>
                  <span className="qualification__rounder"></span>
                  <span className="qualification__line"></span>
                </div>
              </div>

              
            </div>
          </div>
        </div>
    </section>
  )
}

export default Qualification